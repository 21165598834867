/*!
 * Custom Global Rules
 */

@import "../../bower_components/bootstrap/assets/stylesheets/bootstrap/mixins/alerts";

li,
ul {
  list-style-type: none;
  padding-left: 0;
  line-height: 1rem;
}

ul {
  margin: 0;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin: 0;
}

h1,
h2 {
  font-size: $font-size-titolo-single;
}

a {
  transition: $transition-hover-default;
  transition-timing-function: $transition-timing-hover-default;
}

a:focus {
  outline: none !important;
  outline-style: none !important;
}

p {
  font-size: $font-size-content;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

label {
  font-weight: 500;
  margin-bottom: 0;
}

*:focus {
  outline: none !important;
  outline-style: none !important;
}

.no-gutter {
  margin-right: 0;
  margin-left: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.ellipsis {
  padding-right: 5px;
}

.panel {
  .panel-default {
    border-radius: $cards-rounded;
  }
}

.navbar-brand {
  font-size: $font-size-heading-sub;
  line-height: $font-line-height-heading-sub;
  font-weight: $font-sans-bold;
}
.navbar-toggle {
  .icon-bar {
    height: 3px;
    width: 26px;
  }
}

.navbar-collapse {
  box-shadow: none;
}

.row-distribute {
  @extend .row !optional;
  display: flex;
  justify-content: space-around;
}

.form-control-static {
  padding: 0 10px;
  min-height: auto;
}

.form-group {
  .btn {
    min-height: 40px;
  }
  .control-label {
    font-size: $font-size-label-form;
    line-height: $font-line-height-label-form;
  }
  .form-control,
  .btn-meta-bar {
    color: $brand-base;
    transition: $transition-hover-default;
    border-radius: $border-radius-form;
    box-shadow: none;
  }
  .btn-group {
    .btn-meta-bar:hover {
      color: #fff;
      background-color: $brand-light;
    }
    .btn-meta-bar:focus {
      border-color: $brand-light;
      color: #fff;
      border-right: #ccc;
      background-color: $brand-light;
    }
  }
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.bootstrap-select .form-control {
  border: 1px solid $input-border;
}

.well {
  box-shadow: none;
  margin: 0;
}

.well-lg {
  border-radius: $cards-rounded;
}

.help-block {
  margin: 0;
  & > .list-unstyled {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

table.dataTable thead {
  .sorting:after,
  .sorting_asc:after,
  .sorting_desc:after,
  .sorting_asc_disabled:after,
  .sorting_desc_disabled:after {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
  }
  .sorting:after {
    content: "\f0dc";
    /* sort */
  }
  .sorting_asc:after {
    content: "\f0de";
    //color: $brand-accent;
    /* sort-by-attributes */
  }
  .sorting_desc:after {
    content: "\f0dd";
    //color: $brand-accent;
    /* sort-by-attributes-alt */
  }
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  @media #{$max-sm} {
    padding: 4px;
  }
}

.badge {
  &--notifica {
    min-width: auto;
  }
  &--users {
    background-color: $brand-success;
  }
  &--avviso {
    background-color: $brand-danger;
    line-height: 18px;
    border-radius: 36px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    font-size: 14px; // FIXME: Non usare variabili qui per compilare Bootstrap
  }
}

.well {
  &--profilo {
    background-color: #fff;
    margin-bottom: 30px;
  }
  .well-lg {
    padding: 30px;
  }
}

.jumbotron {
  .contaner-fluid &,
  .container & {
    border-radius: $cards-rounded;
  }
}

.alert {
  &-theme {
    @include alert-variant($brand-theme,$brand-theme,#fff);
    & > h4 {
      text-transform: uppercase;
    }
    & > a {
      color: $brand-accent;
      text-decoration: underline;
      &:hover {
        color: #fff;
      }
    }
  }
}

.list-group {
  &--dashboard {
    .list-group-item {
      & {
        border: none;
        border-bottom: 2px solid #fff;
        background-color: $brand-terzo;
      }
      &.active {
        border: none;
        border-bottom: 1px solid #fff;
        background-color: $brand-accent;
      }
    }
  }
}

.modal {
  .modal-header {
    padding: $panel-heading-padding;
    background: $brand-theme;
    .close {
      opacity: 1;
      margin-top: 0;
      text-shadow: none;
      &:hover {
        opacity: 0.7;
      }
    }
    span {
      font-size: $font-size-large;
      color: $background-extralight;
    }
  }
  .modal-title {
    color: $background-extralight;
    font-family: $font-family-alternate;
    font-size: $font-size-large;
    line-height: $line-height-large;
  }
  .modal-content {
    border-radius: 0;
    border: 1px solid darken($brand-theme, 15%);
  }
}


