// Button Multivariant

//  Estende il mixin standard di bootstrap per supportare diversi colori nei vari stati del bottone:

//  - Focus
//  - Hover
//  - Active
//  - Disabled

//  I parametri del bottone sono delle Map di sass, rispettivamente con i valori:

//  $colorMap: (
//   normal: $colore-normal,
//   active: $colore-active,
//   focus: $colore-focus,
//   hover: $colore-hover,
//   disabled: $colore-disabled
//  );


@mixin button-multivariant($colorMap, $backgroundMap, $borderMap) {
  @if not(map-has-key($colorMap, 'normal')) {
    @error " `#{$colorMap}` non è un Map di sass.";
  }
  @if not(map-has-key($backgroundMap, 'normal')) {
    @error " `#{$backgroundMap}` non è un Map di sass.";
  }
  @if not(map-has-key($borderMap, 'normal')) {
    @error " `#{$borderMap}` non è un Map di sass.";
  }

  color: map-get($colorMap,'normal');;
  background-color: map-get($backgroundMap,'normal');;
  border-color: map-get($borderMap,'normal');
  transition: $transition-hover-default;
  transition-timing-function: $transition-timing-hover-default;

  &:focus,
  &.focus {
    color: map-get($colorMap,'focus');
    background-color: map-get($backgroundMap,'focus');
        border-color: map-get($borderMap,'focus');
  }
  &:hover {
    color: map-get($colorMap,'hover');
    background-color: map-get($backgroundMap,'hover');
        border-color: map-get($borderMap,'hover');
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: map-get($colorMap,'active');
    background-color: map-get($backgroundMap,'active');
        border-color: map-get($borderMap,'active');

    &:hover,
    &:focus,
    &.focus {
      color: map-get($colorMap,'focus');
      background-color: map-get($backgroundMap,'focus');
          border-color: map-get($borderMap,'focus');
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus,
    &.focus {
      background-color: map-get($backgroundMap,'disabled');
      border-color: map-get($borderMap,'disabled');
    }
  }

  .badge {
    color: map-get($colorMap,'active');
    background-color: map-get($backgroundMap,'active');
  }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
