.navbar {
  .navbar-toggle {
    border: none;
    &:hover,
    &:focus {
      background-color: transparent;
    }
    span {
      &.icon-bar {
        transition: $transition-hover-default;
        transition-timing-function: $transition-timing-hover-default;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }
      &:nth-child(3) {
        opacity: 0;
      }
      &:nth-child(4) {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }
    &.collapsed {
      span {
        &:nth-child(2),
        &:nth-child(4) {
          transform: rotate(0);
        }
        &:nth-child(3) {
          opacity: 1;
        }
      }
    }
  }
}
