


// PALETTE
// IMPORTANTE! Questo è il file di palette variabili. In altre parole, tutti i valori sono qui
// e non è necessario cercarli lungo tutti i file Sass.
// I file Sass contengono SOLO i comportamenti, questo file contiene tutte le misure.


// FIXME: Rivedere pallette appena abbiamo una style guide

$brand-base: #555555;
$brand-light: #aaaaaa;
$brand-accent: #ffd33f;
$brand-theme: #35424b;
$brand-lightgray: #e6e6e6;
$brand-theme-light: #535e66; //FIXME: Spiegare a Pistarà che nelle pagine web non ci sono i filtri di Illustrator
$brand-secondario: #bfc9cd;
$brand-terzo: #e8ecec;
$background-extralight: #f7f7f7;
$brand-well: #f2f2f2;
$brand-table-highlight: #FFF6D9;

$brand-facebook: #3B5998;
$brand-linkedin: #0077B5;

//FIXME: Conviene usare sempre la map() di Sass, ma per praticità assegno i colori in delle variabili

$brand-corsi: #ff7b39;
$brand-eventi: #a0c319;
$brand-luoghi: #39bdf0;
$brand-esperienze: #d9394b;
$brand-bambini: #9750a5;
$brand-soggetti: #92a7af;

$brand-palette : (
  corsi: $brand-corsi,
  eventi: $brand-eventi,
  luoghi: $brand-luoghi,
  esperienze: $brand-esperienze,
  bambini: $brand-bambini,
  soggetti: $brand-soggetti
);


$gradient-eventi: linear-gradient(to right, rgba(72, 124, 39, 1) 0%,rgba(149, 187, 0, 0.8) 100%);
$gradient-corsi: linear-gradient(to right, rgba(230, 59, 17, 1) 0%,rgba(245, 158, 78, 0.8) 100%);
$gradient-bambini: linear-gradient(to right, rgba(104, 60, 141, 1) 0%,rgba(162, 102, 167, 0.8) 100%);
$gradient-esperienze: linear-gradient(to right, rgba(133, 28, 31, 1) 0%,rgba(194, 57, 75, 0.8) 100%);
$gradient-luoghi: linear-gradient(to right, rgba(0, 113, 171, 1) 0%,rgba(57, 189, 225, 0.8) 100%);
$gradient-soggetti: linear-gradient(to right, rgba(104, 124, 132, 1) 0%,rgba(173, 193, 201, 0.8) 100%); //FIXME: Abbiamo un gradiente per soggetti?

$gradient-palette: (
  corsi: $gradient-corsi,
  eventi: $gradient-eventi,
  luoghi: $gradient-luoghi,
  esperienze: $gradient-esperienze,
  bambini: $gradient-bambini,
  soggetti: $gradient-soggetti
);

// COLORI

$background-dark: $brand-secondario;
$background-light: $brand-terzo;

// ELEMENTI DECORATIVI

$shadow-none: 0 0 0 0 rgba(0,0,0,.2);
$shadow-large: 0 0 15px 2px rgba(0,0,0,.2);

// ANIMAZIONI

$transition-hover-default: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1.000);;
$transition-timing-hover-default: cubic-bezier(0.645, 0.045, 0.355, 1.000); ;

// BREAKPOINTS
// NOTA: Queste variabili sono solo per comodità nella digitazione.
// Sconsiglio vivamente L'uso di variabili con nome per indicare i breakpoint,
// a meno di non voler passare 15 minuti ad ogni uso del breakpoint cercando di ricordare a quanto equivalgono i loro valori.
// I valori sono assegnati tramite string interpolation, vedi http://sass-lang.com/documentation/file.SASS_REFERENCE.html#Interpolation_____
// per maggiori informazioni.

$max-xs: 'screen and (max-width: 767px)';
$min-sm: 'screen and (min-width: 768px)';
$max-sm: 'screen and (max-width: 991px)';
$min-md: 'screen and (min-width: 992px)';
$max-md: 'screen and (max-width: 1199px)';
$min-lg: 'screen and (min-width: 1200px)';

$intervallo-sm: 'screen and (max-width: 991px) and (min-width: 768px)';
$intervallo-md: 'screen and (max-width: 1199px) and (min-width: 992px)';

// DISTANZE

$distance-extrasmall: 5px;
$distance-small: 15px;
$distance-normal: 30px;
$distance-medium: 50px; //FIXME: Vedere se ha senso mettere questo valore in rem o variarlo per breakpoint.
$distance-large: 90px;


$distance-hr-intestazione: 10px;


$navbar-distance-small: 10px;
$navbar-distance-medium: 25px;
$item-distance-medium: 30px;

$topbar-link-distance-extrasmall: 6px;
$topbar-link-distance-small: 9px;

$distance-footer-hr-small: 6px;
$distance-footer-hr-medium: 16px;

$ricerca-btn-distance-extrasmall: 9px;
$ricerca-btn-distance-small: 11px;
$ricerca-btn-distance-medium: 25px;

$ricerca-distance-small: 5px;

$distance-list-small: 5px; // Separatore per accordion in sidebar
$distance-list-medium: 50px;

$distance-navbar-toggle-horizontal-small: 10px;
$distance-navbar-toggle-vertical-small: 5px;

$distance-navitem-horizontal-small: 0;
$distance-navitem-vertical-small: 10px;

$distance-footer-vertical-small: 20px;

$distance-footer-link-small: 5px;
$distance-footer-link-medium: 10px;

$menu-distance-top: 15px;

$distance-scheda-extrasmall: 5px;
$distance-scheda-small: 10px;
$distance-scheda-medium: 15px;

$distance-sidebar-heading: 30px;

//

$distance-block-extrasmall-xs: 10px;
$distance-block-extrasmall-md: 10px;

$distance-block-small-xs: 20px;
$distance-block-small-md: 30px;

$distance-block-medium-xs: 30px;
$distance-block-medium-md: 50px;

$distance-block-large-xs: 40px;
$distance-block-large-md: 60px;

$distance-block-extralarge-xs: 50px;
$distance-block-extralarge-md: 100px;

$distance-sidebar-small-xs: 15px;
$distance-sidebar-small-md: 15px;
$distance-sidebar-medium-md: 30px;
$distance-sidebar-large-md: 50px;

// DIMENSIONI:

$navbar-toggle-height: 42px;
$heading-height-sm: 400px;
$ricerca-height-xs: 40px;
$subheading-height-small: 200px;
$subheading-height-medium: 200px;

$divider-width: 40px;

$recensione-item-height-md: 230px;

// CURVE E ANGOLI

$cards-rounded: 3px;
$search-rounded: 25px;

// TIPOGRAFIA - Per Filippo o chi estenderà il Sass, eventualmente: si - vanno qui, le variabili sono dichiarate qui, in typography vanno solo i tag html.
//
// Riferimenti a 1rem = 16px
//
// 8px = 0.5rem
// 9px = 0.5625rem
// 10px = 0.625rem
// 11px = 0.6875rem
// 12px = 0.75rem
// 13px = 0.8125rem
// 14px = 0.875rem
// 15px = 0.9375rem
// 16px = 1rem (base)
// 18px = 1.125rem
// 20px = 1.25rem
// 22px = 1.375rem
// 24px = 1.5rem
// 26px = 1.625rem
// 28px = 1.75rem
// 30px = 1.875rem
// 32px = 2rem
// 34px = 2.125rem
// 36px = 2.25rem
// 38px = 2.375rem
// 40px = 2.5rem
// 42px = 2.625rem
// 44px = 2.75rem
// 46px = 2.875rem
// 48px = 3rem
// 50px = 3.125rem
// 52px = 3.25rem
// 60px = 3.75rem
// 72px = 4.5rem
$font-family-alternate: 'Barlow Condensed', Helvetica, Arial, sans-serif;

$font-line-height-accordion-titolo: 1.188rem;
$font-line-height-avviso-dashboard: 1.375rem;
$font-line-height-button-default: 1.5rem;
$font-line-height-heading-sub: 1.5rem;
$font-line-height-footer-link: 1.125rem;
$font-line-height-footer-link-nav: 1.125rem;
$font-line-height-help-form: 1rem;
$font-line-height-itinerario: 1.5rem;
$font-line-height-item-list: 1.125rem;
$font-line-height-label-form: 0.875rem;
$font-line-height-logo: 1.875rem;
$font-line-height-prog-it: 1.5rem;
$font-line-height-prog-it-data: 1.375rem;
$font-line-height-prog-it-giorno: 2rem;
$font-line-height-recensione-scheda: 1.5rem;
$font-line-height-sidebar-checkbox-label: 0.875rem;
$font-line-height-sidebar-datepicker-label: 0.875rem;
$font-line-height-sidebar-title: 0.875rem;
$font-line-height-scheda-titolo: 1.188rem;
$font-line-height-subfooter-link: 1.375rem;
$font-line-height-titolo-single: 2.125rem;
$font-line-height-topbar-link: 1.5rem;


$font-size-sm: 1rem;
$font-size-accordion-titolo: 0.875rem;
$font-size-accordion-titolo-sidebar: 1.875rem;
$font-size-avviso-dashboard: 1.125rem;
$font-acquista-label: 0.8125rem;
$font-acquista-prezzo: 2rem;
$font-size-button-pagelink: 0.875rem;
$font-size-button-cta-small: 1rem;
$font-size-button-cta-alt: 1.25rem;
$font-size-button-cta-alt-small: 0.875rem;
$font-size-button-default: 1.25rem;
$font-size-content: 1rem;
$font-size-counter-titolo: 1.625rem;
$font-size-dashboard-titolo: 1.875rem;
$font-size-heading-sub: 0.875rem;
$font-size-footer-head: 0.8125rem;
$font-size-footer-link: 0.8125rem;
$font-size-footer-sub: 0.8125rem;
$font-size-footer-link-nav: 0.8125rem;
$font-size-form-titolo: 1.125rem;
$font-size-help-form: 0.875rem;
$font-size-help-form-small: 0.75rem;
$font-size-index: 1.5rem;
$font-size-indicazioni: 0.875rem;
$font-size-intestazione-meta: 1rem;
$font-size-intestazione-recensione: 0.875rem;
$font-size-intestazione-titolo: 1.875rem;
$font-size-intestazione-sottotitolo: 1.25rem;
$font-size-item-list: 0.9375rem;
$font-size-item-table: 0.8125rem;
$font-size-itinerario: 1rem;
$font-size-label-form: 0.75rem;
$font-size-lista-item: 0.875rem;
$font-size-lista-dettagli-item: 0.75rem;
$font-size-logo: 0.8125rem;
$font-size-prog-it: 1rem;
$font-size-prog-it-data: 1.125rem;
$font-size-prog-it-giorno: 2.25rem;
$font-size-recensione-scheda: 1rem;
$font-size-registrazione: 1rem;
$font-size-registrazione-item: 0.875rem;
$font-size-review-scheda: 0.875rem;
$font-size-search: 0.875rem;
$font-size-search-addon: 0.875rem;
$font-size-sidebar-checkbox-label: 0.875rem;
$font-size-sidebar-datepicker-label: 0.875rem;
$font-size-sidebar-title: 0.75rem;
$font-size-scheda-titolo: 1rem;
$font-size-scheda-titolo-servizio: 1.125rem;
$font-size-scheda-categoria: 0.8125rem;
$font-size-scheda-sottocategoria: 0.75rem;
$font-size-scheda-meta: 0.8125rem;
$font-size-prezzo: 1rem;
$font-size-sconto: 0.875rem;
$font-size-subfooter-link: 0.6875rem;
$font-size-titolo-single: 1.875rem;


%font-size-menu {
  font-size: 0.8125rem;
  line-height: 0.8125rem;

  @media #{$min-md} {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

%font-size-topbar-link {
  font-size: 0.8125rem;
  line-height: 1.125rem;

  @media #{$min-md} {
    font-size: 0.75rem;
    line-height: 1.5rem !important;
  }
}

%font-size-titolo {
  font-size: 1.875rem;
  line-height: 2.25rem;

  @media #{$min-md} {
    font-size: 2.5rem;
    line-height: 2.25rem;
  }
}

%font-size-titolo-slide {
  font-size: 2.5rem;
  line-height: 3rem;

  @media #{$min-md} {
    font-size: 3.75rem;
    line-height: 4.5rem;
  }
}

%font-size-testo-slide {
  font-size: 0.875rem;
  line-height: 1.5rem;

  @media #{$min-md} {
    font-size: 1.125rem;
    line-height: 1.625rem;
  }
}

%font-size-intestazione {
  font-size: 1.5rem;
  line-height: 1.875rem;

  @media #{$min-md} {
    font-size: 1.875rem;
    line-height: 2rem;
  }
}

$font-size-location: 0.875rem;

$font-sans-light: 300;
$font-sans-regular: 400;
$font-sans-semibold: 500;
$font-sans-bold: 600;
$font-sans-extrabold: 700;

$height-scheda: 376px;

$font-awesome-size: 1.25rem;

// OVERRIDE REGOLE DI BOOTSTRAP SPECIFICHE PER IL TEMA DI COSE DA FARE

$gray-dark: $brand-base;
$brand-primary: $brand-theme;
$brand-success: #A0C319;
$breadcrumb-color: $brand-base;
$breadcrumb-active-color: $brand-base;
$navbar-inverse-toggle-border-color: transparent;
$navbar-inverse-toggle-hover-bg: transparent;

$border-radius-base: 0px;
$border-radius-large: 35px;
$border-radius-small: 0px;
$border-radius-form: 3px;

$navbar-height: 40px;

$panel-heading-padding: 5px 15px;

$font-family-sans-serif: "Barlow", Helvetica, Arial, sans-serif;

$input-height-base: 40px;

$input-height-large: 50px;

$font-size-large: 20px; //FIXME: Bootstrap 3 non supporta i rem per i font degli oggetti nativi. ahi ahi ahi.

$well-border: transparent;
$well-bg: $brand-well;
