.breadcrumb {
  background-color: transparent;
  font-family: $font-family-alternate;
  font-weight: $font-sans-semibold;
  > li a {
    color: $breadcrumb-active-color;
  }
  .active {
    font-weight: $font-sans-bold;
  }
}
