//FIXME: Semplificare e ridurre il numero di tabelle colori

$lineBackground: (
  normal: transparent,
  active: transparent,
  focus: transparent,
  hover: transparent,
  disabled: transparent,
);

$dropdownColor: (
  normal: $brand-terzo,
  active: $brand-terzo,
  focus: $brand-terzo,
  hover: lighten($brand-terzo,10%),
  disabled: $brand-light
);

$dropdownBackground: (
  normal: $brand-theme,
  active: $brand-theme,
  focus: $brand-theme,
  hover: darken($brand-theme,2%),
  disabled: $brand-light
);

$dropdownBorder: (
  normal: $brand-secondario,
  active: $brand-secondario,
  focus: $brand-secondario,
  hover: $brand-secondario,
  disabled: $brand-base
);

$searchColor: (
  normal: $brand-theme,
  active: $brand-theme,
  focus: $brand-theme,
  hover: $brand-theme,
  disabled: $brand-light
);

$searchBackground: (
  normal: $brand-accent,
  active: lighten($brand-terzo,5%),
  focus: darken($brand-accent,5%),
  hover: $brand-terzo,
  disabled: $brand-light
);

$searchBorder: (
  normal: $brand-secondario,
  active: $brand-secondario,
  focus: transparent,
  hover: $brand-secondario,
  disabled: $brand-base
);

$linkColor: (
  normal: $background-extralight,
  active: $background-extralight,
  focus: $background-extralight,
  hover: $background-extralight,
  disabled: $brand-light
);

$linkBackground: (
  normal: $brand-theme,
  active: $brand-theme,
  focus: darken($brand-theme,5%),
  hover: lighten($brand-theme,5%),
  disabled: $brand-light
);

$linkBorder: (
  normal: transparent,
  active: transparent,
  focus: transparent,
  hover: transparent,
  disabled: $brand-base
);

$metaColor: (
  normal: $brand-theme,
  active: #fff,
  focus: $brand-theme,
  hover: #fff,
  disabled: $brand-light
);

$metaBackground: (
  normal: $brand-accent,
  active: $brand-accent,
  focus: $brand-accent,
  hover: $brand-accent,
  disabled: $brand-light
);

$metaBorder: (
  normal: transparent,
  active: transparent,
  focus: transparent,
  hover: transparent,
  disabled: $brand-base
);

$breadcrumbColor: (
  normal: $brand-theme,
  active: #fff,
  focus: $brand-theme,
  hover: #fff,
  disabled: $brand-light
);

$breadcrumbBackground: (
  normal: $brand-accent,
  active: $brand-accent,
  focus: $brand-accent,
  hover: $brand-accent,
  disabled: $brand-light
);

$breadcrumbBorder: (
  normal: transparent,
  active: transparent,
  focus: transparent,
  hover: transparent,
  disabled: $brand-base
);

$cta-line__Color: (
  normal: $brand-theme,
  active: $brand-theme,
  focus: darken($brand-theme,15%),
  hover: lighten($brand-theme,15%),
  disabled: $brand-light
);

$primaryColor: (
  normal: $brand-theme,
  active: #fff,
  focus: #fff,
  hover: #fff,
  disabled: rgba(#fff, 0.3)
);

$primaryBackground: (
  normal: $brand-accent,
  active: lighten($brand-accent,10%),
  focus: darken($brand-accent,10%),
  hover: lighten($brand-accent,10%),
  disabled: rgba($brand-accent, 0.3)
);

$primaryBorder: (
  normal: transparent,
  active: transparent,
  focus: transparent,
  hover: transparent,
  disabled: transparent
);

$defaultColor: (
  normal: #fff,
  active: #fff,
  focus: #fff,
  hover: #fff,
  disabled: rgba(#fff, 0.3)
);

$defaultBackground: (
  normal: $brand-theme,
  active: lighten($brand-theme,10%),
  focus: darken($brand-theme,10%),
  hover: lighten($brand-theme,10%),
  disabled: rgba($brand-theme, 0.3)
);

$defaultBorder: (
  normal: transparent,
  active: transparent,
  focus: transparent,
  hover: transparent,
  disabled: transparent
);

$facebookBackground: (
  normal: $brand-facebook,
  active: lighten($brand-facebook,10%),
  focus: darken($brand-facebook,10%),
  hover: lighten($brand-facebook,10%),
  disabled: rgba($brand-facebook, 0.3)
);

$linkedinBackground: (
  normal: $brand-linkedin,
  active: lighten($brand-linkedin,10%),
  focus: darken($brand-linkedin,10%),
  hover: lighten($brand-linkedin,10%),
  disabled: rgba($brand-linkedin, 0.3)
);

$navigationBackground: (
  normal: $brand-accent,
  active: lighten($brand-accent, 10%),
  focus: darken($brand-accent, 10%),
  hover: darken($brand-accent, 10%),
  disabled: rgba($brand-accent, 0.3)
);



.btn-default {
  @include button-multivariant($defaultColor,$defaultBackground,$defaultBorder);
  line-height: $font-line-height-button-default;
  font-size: $font-size-button-default;
  font-weight: $font-sans-extrabold;
  border-radius: 30px;
  &.btn-lg {
    padding: 17px 36px;
  }
}

.btn-primary {
  @include button-multivariant($primaryColor,$primaryBackground,$defaultBorder);
  line-height: $font-line-height-button-default;
  font-size: $font-size-button-default;
  font-weight: $font-sans-extrabold;
  border-radius: 30px;
  &.btn-lg {
    padding: 17px 36px;
  }
}

.btn-dropdown {
  @include button-multivariant($dropdownColor,$dropdownBackground,$dropdownBorder);
}

.btn-search {
  @include button-multivariant($searchColor,$searchBackground,$searchBorder);
}

.btn-meta {
  @include button-multivariant($metaColor,$metaBackground,$metaBorder);
  border-radius: 30px;
}

.btn-meta-filter {
  @extend .btn-primary;
  border-radius: 30px;
  font-size:  $font-size-base;
}

.btn-filter{
  @extend .btn-primary;
  border-radius: 30px;
  margin-top: 15px;
  margin-bottom: 0;
}

.btn-list {
  @include button-multivariant($metaColor,$metaBackground,$metaBorder);
  border-radius: 30px;
  font-weight: $font-sans-bold;
}

.btn-breadcrumb {
  @include button-multivariant($breadcrumbColor,$breadcrumbBackground,$breadcrumbBorder);
  border-radius: 30px;
  padding: 2px 8px;
  font-weight: $font-sans-semibold;
  font-family: $font-family-alternate;
}

.btn-pagelink {
  @include button-multivariant($linkColor,$linkBackground,$linkBorder);
  border-radius: 30px;
  font-size: $font-size-button-pagelink;
  font-weight: $font-sans-extrabold;
  text-transform: uppercase;
}

.btn-cta-line {
  @include button-multivariant($cta-line__Color,$lineBackground,$cta-line__Color);
  line-height: 1;
  padding: 17px 20px;
  border-radius: 30px;
  font-size: $font-size-button-cta-alt;
  font-weight: $font-sans-extrabold;
  border-width: 2px;
}

.btn-cta-line-small {
  @extend .btn-cta-line;
  padding: 10px 15px;
  font-size: $font-size-button-cta-small;
  margin-bottom: 15px;
  @media #{$intervallo-md} {
    font-size: $font-size-button-cta-alt-small;
  }
}

.btn-tag-line {
  @extend .btn-cta-line;
  padding: 8px 12px;
  border-width: 1px;
  margin-right: 10px;
  margin-bottom: 5px;
}

.btn-cta-alternate {
  @include button-multivariant($linkColor,$linkBackground,$linkBorder);
  border-width: 1.5px;
  line-height: 1;
  padding: 17px 20px;
  border-radius: 30px;
  font-size: $font-size-button-cta-alt;
  @media #{$intervallo-md} {
    font-size: $font-size-button-cta-alt-small;
  }
  font-weight: $font-sans-extrabold;
  border-width: 2px;
}
.btn-cta-alternate-small {
  @include button-multivariant($linkColor,$linkBackground,$linkBorder);
  border-radius: 30px;
  padding: 10px 15px;
  font-size: $font-size-button-cta-small;
  font-weight: $font-sans-extrabold;
  margin-bottom: 15px;
  @media #{$intervallo-md} {
    font-size: $font-size-button-cta-alt-small;
  }
}

.btn-cta-warning {
  @extend .btn-warning;
  line-height: 1;
  border-radius: 30px;
  padding: 10px 15px;
  font-size: $font-size-button-cta-alt;
  font-weight: $font-sans-extrabold;
  border-width: 0;
}

.btn-cta-success {
  @extend .btn-success;
  line-height: 1;
  border-radius: 30px;
  padding: 10px 15px;
  font-size: $font-size-button-cta-alt;
  font-weight: $font-sans-extrabold;
  border-width: 0;
}

.btn-facebook-login {
  @include button-multivariant($defaultColor,$facebookBackground,$defaultBorder);
}

.btn-linkedin-login {
  @include button-multivariant($defaultColor,$linkedinBackground,$defaultBorder);
}

.btn-facebook-login,
.btn-linkedin-login {
  border-radius: 30px;
  font-weight: $font-sans-bold;
  display: flex;
  align-items: center;
  & > i {
    margin-left: 5%;
    margin-right: 10px;
    &:before {
      padding-right: 10px;
    }
    border-right: 1px solid rgba(#fff,0.3);
  }
}


.btn-navigation {
  @include button-multivariant($searchColor, $navigationBackground, $defaultBorder);
  border-radius: $cards-rounded;
  display: none;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  font-family: $headings-font-family;
  font-weight: $font-sans-extrabold;
  font-size: $font-size-accordion-titolo;
  line-height: $font-line-height-accordion-titolo;

  &.show {
    display: flex !important;
  }
}
